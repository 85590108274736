<template>
    <v-container grid-list-xl>
        <v-stepper v-model="step" vertical>
            <v-stepper-step step="1" :complete="item.status >= 1" :editable="!item.status || item.status == 0 || item.part1 == 2" :rules="[() => item.part1 != 2]">
                {{ $t("Dados Básicos") }}
                <small>{{ statusMessage(item.part1) }}</small>
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-form ref="form1" v-model="valid1" lazy-validation @submit.prevent="validate1">
                    <Basic :item="item" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid1" color="success" @click="validate1">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="2" :complete="item.status >= 2" :editable="item.status == 1 || item.part2 == 2" :rules="[() => item.part2 != 2]">
                {{ $t("Localidade") }}
                <small>{{ statusMessage(item.part2) }}</small>
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-form ref="form2" v-model="valid2" lazy-validation @submit.prevent="validat2e">
                    <Address :item="item" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid2" color="success" @click="validate2">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="3" :complete="item.status >= 3" :editable="item.status == 2 || item.part3 == 2" :rules="[() => item.part3 != 2]">
                {{ $t("Dados Adicionais") }}
                <small>{{ statusMessage(item.part3) }}</small>
            </v-stepper-step>

            <v-stepper-content step="3">
                <v-form ref="form3" v-model="valid3" lazy-validation @submit.prevent="validate3">
                    <Additional :item="item" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid3" color="success" @click="validate3">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="4" :complete="item.status >= 4" :editable="item.status == 3 || item.part4 == 2" :rules="[() => item.part4 != 2]">
                {{ $t("Meio de transporte") }}
                <small>{{ statusMessage(item.part4) }}</small>
            </v-stepper-step>

            <v-stepper-content step="4">
                <v-form ref="form4" v-model="valid4" lazy-validation @submit.prevent="validate4">
                    <Vehicle :item="item" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid4" color="success" @click="validate4">{{ $t("Salvar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="5" :complete="item.status == 5 && allFormAccepted" editable>
                {{ $t("Status final do cadastro.") }}
            </v-stepper-step>

            <v-stepper-content step="5">
                <v-flex xs12>
                    <v-alert outlined type="success" text v-if="item.status == 5 && allFormAccepted"> Especialista ativo </v-alert>
                    <v-alert outlined type="warning" text v-else> Aguardando aprovação. </v-alert>
                </v-flex>
            </v-stepper-content>
        </v-stepper>
    </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import Address from "@/components/form/Address";
import Basic from "@/pages/Expert/basic";
import Vehicle from "@/pages/Expert/vehicle";
import Additional from "@/pages/Expert/additional";

export default {
    directives: { mask },
    components: { Address, Basic, Vehicle, Additional },
    data() {
        return {
            valid1: true,
            valid2: true,
            valid3: true,
            valid4: true,
            item: null,
            step: 1,
            users: null,
            lock: false,
        };
    },
    computed: {
        allFormAccepted() {
            return this.item.part1 == 1 && this.item.part2 == 1 && this.item.part3 == 1 && this.item.part4 == 1;
        },
    },

    methods: {
        statusMessage(step) {
            return step == 2 ? "Reprovado" : step == 1 ? "Aprovado" : "";
        },
        getInfo() {
            this.$http
                .get(`experts/my`)
                .then((result) => {
                    if (result) {
                        this.item = result;
                        this.changeStep();
                    }
                })
                .catch(() => {
                    this.item = null;
                });
        },
        validate1() {
            if (this.$refs.form1.validate()) {
                this.item.part1 = 0;
                this.save(1);
            }
        },
        validate2() {
            if (this.$refs.form2.validate()) {
                this.item.part2 = 0;
                this.save(2);
            }
        },
        validate3() {
            if (this.$refs.form3.validate()) {
                this.item.part3 = 0;
                this.save(3);
            }
        },
        validate4() {
            if (this.$refs.form4.validate()) {
                this.item.part4 = 0;
                this.save(4);
            }
        },

        changeStep() {
            this.step = this.item.status < 5 ? this.item.status + 1 : this.item.status;
        },

        save(goToStep) {
            this.lock = true;
            if (!this.item.status || this.item.status < goToStep) this.item.status = goToStep;
            if (this.item.id) {
                this.$http
                    .put("experts", this.item.id, this.item)
                    .then(() => {
                        this.$eventHub.$emit("msgSuccess", this.$t("Dados salvo com sucesso."));
                        this.lock = false;
                        this.changeStep();
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar dados."));
                        this.lock = false;
                    });
            } else {
                this.$http
                    .post("experts", this.item)
                    .then((result) => {
                        this.$eventHub.$emit("msgSuccess", this.$t("Dados salvo com sucesso."));
                        if (result.id) {
                            this.item.id = result.id;
                        }
                        this.lock = false;
                        this.changeStep();
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar dados."));
                        this.lock = false;
                    });
            }
        },
        clearValidation() {
            if (this.$refs.form1) {
                this.$refs.form1.reset();
            }
            if (this.$refs.form2) {
                this.$refs.form2.reset();
            }
            if (this.$refs.form3) {
                this.$refs.form3.reset();
            }
            if (this.$refs.form4) {
                this.$refs.form4.reset();
            }
        },
    },

    mounted() {
        this.getInfo();
        this.clearValidation();
    },
};
</script>
